import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"

/* Basic content page structure */

class CookiesPolicy extends React.Component {
  componentDidMount() {
    // Inactivity()
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Meet MJN - Cookies Policy</title>
        </Helmet>
        <main role="main" className="content-main">

          <section className="hero-area -no-content">

          </section>

          <section className="rich-text -bg-white">
            <nav className="breadcrumb" role="navigation">
              <Link to="/home" title="Home">
                <i className="fas fa-arrow-left"></i>
                Home
              </Link>
            </nav>

            <h1>Cookies Policy</h1>

            <h2>What are cookies and how do we use them?</h2>
            
            <p>Like most websites you visit, our website ("site") uses cookies and other similar technologies to distinguish you from other users of our site and to store and manage user preferences, deliver targeted advertising, enable content, and gather analytic and user data, for example. This helps us to provide you with a good experience when you browse our site and also allows us to improve our site.</p>

            <h3>Cookies</h3>

            <p>Cookies are text files containing small amounts of information which are downloaded to your device when you visit a website or embedded in a web page. Cookies may then be sent back to the originating website on each subsequent visit, or to another website that recognises that cookie. Cookies are useful because they allow a website to recognise a user's device and to remember your actions and preferences (such as login details, language, font size and other display preferences) over a period of time.</p>
            <p>Where possible, security measures are set in place to prevent unauthorised access to our cookies and similar technologies. A unique identifier ensures that only we and/or our authorised service providers have access to cookie data.</p>
            <p>We rely on your consent to use certain types of cookies (except "strictly necessary cookies", as described below). You can refuse cookies at any time by changing your settings while entering a website, referring to the Consent Portal or changing the cookie settings in your browser.</p>
            <p>You can find more information about cookies, including how to see what cookies have been set on your device and how to manage and delete them, at <a href="www.aboutcookies.org" title="About Cookies" target="_blank" rel="noopener noreferrer">www.aboutcookies.org</a> and <a href="www.youronlinechoices.eu" title="Your online choices" target="_blank" rel="noopener noreferrer">www.youronlinechoices.eu</a>.</p>

            <h3>Web beacons</h3>

            <p>Web beacons (also known as internet tags, pixel tags and clear GIFs) are typically transparent graphic images placed on a site or email. Web beacons are used in combination with cookies to measure the actions of visitors on websites. We may use beacons to obtain information such as the IP address of the computer that downloaded the page on which the beacon appears, the URL of the page on which the beacon appears, the time the page containing the beacon was viewed, and the type of browser used to view the page.</p>

            <h3>IP addresses and URLs</h3>

            <p>An IP address is a unique identifier that certain electronic devices use to identify and communicate with each other on the internet. When you visit our sites, we may view that IP address of the device that you use to connect to the internet using a web beacon. We use this information to determine the general physical location of the device and understand from what geographic regions our site visitors come. We may use this information to change the way we present our sites to you to enhance your visit.</p>
            <p>A URL (uniform resource locator) is a unique identifier or address for each resource on the internet, in effect it is the address for the web page that you are visiting. We will use this information to see which sites and pages are visited and the way that you navigate through our site.</p>

            <h2>How long do cookies last?</h2>

            <p>We use cookies and similar technologies that only remain on your device for as long as you keep your browser active (session) and cookies and similar technologies that remain on your device for a longer period (persistent). You are free to block, delete, or disable these technologies if your device allows this. You can manage your cookies and your cookie preferences in your browser or device settings.</p>

            <h2>What types of cookies are used on this site?</h2>

            <p>We use different types of cookies on our sites. Although they mainly work in the same way there are some differences:</p>

            <table cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th>Type of Cookie</th>
                  <th>Brief Description</th>
                  <th>How long will the cookie remain on your computer?</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><p><strong>Strictly necessary cookies</strong></p></td>
                  <td><p>These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services.</p></td>
                  <td>Session and Persistent</td>
                </tr>
                <tr>
                  <td><p><strong>Analytical/performance cookies</strong></p></td>
                  <td><p>They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily. These cookies may be provided by our third party analytic provider but are only used for purposes related to our sites.</p></td>
                  <td>Session and Persistent</td>
                </tr>
                <tr>
                  <td><p><strong>Functionality cookies</strong></p></td>
                  <td><p>These are used to recognise you and choices you make when you return to our website. This enables us to enhance and personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region). In addition, cookies may allow some website embedded functionalities (for example, displaying YouTube videos) to work properly.</p></td>
                  <td>Session and Persistent</td>
                </tr>
                <tr>
                  <td><p><strong>Social Media/Sharing cookies</strong></p></td>
                  <td><p>Our website may include some social media features, such as the Facebook "Like" button, the Twitter "Tweet" button, the LinkedIn "Share" button and so on. These features may collect your IP address, which page you are visiting on our website, and may set a cookie or employ similar technologies to enable the feature to function properly. Social media features are either hosted by a third party or directly on our website. We have no control over how the social networks may collect and use your personal information and their use is subject to the social network’s own privacy policy.</p></td>
                  <td>Session and Persistent</td>
                </tr>
                <tr>
                  <td><p><strong>Targeting/Re-targeting cookies</strong></p></td>
                  <td><p>These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our websites and the advertising displayed on them more relevant to your interests. We may also share this information with third parties for this purpose.</p></td>
                  <td>Session and Persistent</td>
                </tr>
              </tbody>
            </table>

            <p className="uppercase"><strong>You can find more information about the individual cookies we use and the purposes for which we use them in the Consent Portal.</strong></p>

            <h2>What about third party cookies?</h2>

            <p>Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies. </p>

            <h2>How to control and delete cookies</h2>

            <p>When you first use our website, you will see a "pop up" cookies notice.</p>

            <p className="uppercase"><strong>By using this website after accepting the cookies notice, or by browsing our website after you have been presented with this notice, you agree to us placing cookies on your device in accordance with the terms of this Cookies Policy.</strong></p>

            <p>The majority of web browsers accept cookies, but you can usually change the web browser’s settings to refuse new cookies, disable existing ones or simply let you know when new ones are sent to your device. If you do not consent to our use of any of the cookies listed above, please disable them following the below instructions so that cookies from this website cannot be placed on your device.</p>

            <p>In order to do this, follow the instructions provided by your browser (usually located within the "Help", "Tools" or "Edit" facility). Alternatively, you can visit <a href="http://www.attacat.co.uk/resources/cookies/how-to-ban" title="Cookies - How to ban" target="_blank" rel="noopener noreferrer">http://www.attacat.co.uk/resources/cookies/how-to-ban</a> for more information on how to manage cookies. However, please be aware that, if you refuse or disable cookies, some of the website’s functionality may be lost.</p>

            <p>In addition, disabling a cookie or category of cookie does not delete the cookie from your device; you will need to do this yourself from within your browser.</p>

            <h2>Changes to our use of cookies</h2>

            <p>Any changes to our use of cookies for this website will be posted here or on the Consent Portal and, if necessary, signposted from our web pages highlighting any changes.</p>

            <h2>Contact Information</h2>

            <p>If you have any queries in relation to this notice or our use of cookies, please contact us at: <a href="mailto:PrivacyOffice@rb.com" title="Email Privacy Office @ RB">PrivacyOffice@rb.com</a></p>
            
            <p>You may also write to us at:</p>

            <address>RB Global Privacy Office
            <br />Turner House
            <br />103-105 Bath Road
            <br />Slough
            <br />SL1 3UH</address>

            <p>You can find out more information about how we process personal data in our <Link to="/privacy-notice" title="Privacy Notice">Privacy Notice</Link>.</p>

          </section>
        </main>
      </Layout>
    )
  }
} 

export default CookiesPolicy